import React, { useEffect, useState } from 'react';

import { useMeasure, useWindowSize } from 'react-use';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { CheckboxComponent, InputComponent, SelectComponent } from '@zolteam/axenergie-ui-library';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import FilterButton from '../../../components/atoms/FilterButton';
import SearchInputComponent from '../../../components/atoms/SearchInputComponent';

// Constants
import strings from '../../../constants/strings';

// Services and hooks
import AdherentBookService from '../../../services/AdherentBookService';
import { useAppContext } from '../../../store/AppContext';
import AdherentService from '../../../services/AdherentService';
import AdherentMap from '../../../components/molecules/AdherentMap';
import {
  ACTIVITIES, EMPLOYEE_NUMBERS, JOBS, ROLES,
} from '../../../constants/adherents';

const AdherentBookMap = () => {
  const location = useLocation();

  // User searching by url param
  const [userSearchedUrl, setUserSearchedUrl] = useState(false);
  const userQueried = new URLSearchParams(location.search).get('query') || null;

  // Filters related states
  const [regionFilter, setRegionFilter] = useState(undefined);
  const [search, setSearch] = useState('');
  const [cooperativeRole, setCooperativeRole] = useState(null);
  const [jobType, setJobType] = useState(null);
  const [activities, setActivities] = useState([]);
  const [employeeAmount, setEmployeeAmount] = useState(0);
  const [enteredNetworkAt, setEnteredNetworkAt] = useState(null);

  // Measures relative variables
  const itemsMargin = 24;
  const [containerRef, { width }] = useMeasure();
  const { width: screenWidth } = useWindowSize();
  const columnWidth = screenWidth < 295 + 2 * itemsMargin + 24 ? screenWidth - 2 * itemsMargin - 24 : 295;

  // hooks
  const [, appDispatch] = useAppContext();

  // Queries
  const getRegions = useQuery('getRegions', () => AdherentService.getRegions());
  const getAll = useQuery({
    queryKey: [
      'getAllAdherents',
      regionFilter, cooperativeRole?.value, jobType?.value, activities, employeeAmount?.value, enteredNetworkAt,
    ],
    queryFn: () => AdherentBookService.getAllAdherent({
      search: search || (userSearchedUrl ? false : userQueried) || undefined,
      regionId: regionFilter,
      cooperativeRole: cooperativeRole?.value,
      jobType: jobType?.value,
      activities: activities.length > 0 ? activities : undefined,
      employeeAmount: employeeAmount?.value,
      enteredNetworkAt: enteredNetworkAt || undefined,
    }),
  });

  const getContainerWidth = () => {
    let nbColumns = Math.floor(width / columnWidth);
    if (nbColumns < 1) {
      nbColumns = 1;
    } else if (nbColumns > 4) {
      nbColumns = 4;
    }
    return (nbColumns * columnWidth) + itemsMargin;
  };

  const doSearch = () => {
    getAll.refetch();
  };

  useEffect(() => {
    if (userQueried) {
      setUserSearchedUrl(true);
      setSearch(userQueried);
    }
  }, []);

  useEffect(() => {
    if (search === '') {
      doSearch();
    }
  }, [search]);

  useEffect(() => {
    if (getAll.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getUser, isSuccess: false },
      });
    }
  }, [getAll.isError]);

  useEffect(() => {
    if (getRegions.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getRegion, isSuccess: false },
      });
    }
  }, [getRegions.isError]);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex justify-start f-column align-start pl-4" ref={containerRef}>
        <div
          className="d-flex f-row f-wrap justify-between align-center mt-4"
          style={{ width: getContainerWidth() }}
        >
          <div
            className="mr-5 d-flex f-column align-start"
          >
            <h2 className="medium-weight mh-0">{strings.adherentMap}</h2>
            <span className="grey-400-text mb-5">{strings.regionBookSubtitle}</span>
          </div>
        </div>
        {/* Filters */}

        <div
          className="white-background pv-3 mb-2 d-flex f-row f-wrap align-center justify-between"
          style={{ width: getContainerWidth() }}
        >
          <div>
            <FilterButton
              onClick={() => setRegionFilter(undefined)}
              isSelected={!regionFilter}
              title={strings.allRegions}
            />
            {
              getRegions?.data?.data?.regions?.map(({ id, name }) => (
                <FilterButton
                  key={id}
                  onClick={() => setRegionFilter(id)}
                  isSelected={id === regionFilter}
                  title={name}
                />
              ))
            }
          </div>
          <div className="form-input-width mh-3 mv-2">
            <SearchInputComponent
              onChange={setSearch}
              id="adherent-search"
              value={search}
              onClick={doSearch}
            />
          </div>
          <div className="d-flex f-wrap">
            <div className="form-input-width small m-3">
              <SelectComponent
                name="adherent-role"
                label={strings.adherentRole}
                data={ROLES}
                value={cooperativeRole}
                setValue={setCooperativeRole}
                isClearable
                theme="dark"
              />
            </div>
            <div className="form-input-width small m-3">
              <SelectComponent
                name="adherent-job"
                label={strings.adherentJob}
                data={JOBS}
                value={jobType}
                setValue={setJobType}
                isClearable
                theme="dark"
              />
            </div>
            <div className="form-input-width small m-3">
              <SelectComponent
                name="employee-number"
                label={strings.employeeNumber}
                data={EMPLOYEE_NUMBERS}
                value={employeeAmount}
                setValue={setEmployeeAmount}
                isClearable
                theme="dark"
              />
            </div>
            <div className="form-input-width small m-3">
              <p className="grey-400-text medium-text mh-2 mv-0">{strings.adherentSearchEntry}</p>
              <InputComponent
                onChange={setEnteredNetworkAt}
                value={enteredNetworkAt}
                id="adherent-entry"
                name="adherent-entry"
                type="date"
              />
            </div>
            <div className="form-input-width small m-3">
              <p className="grey-400-text medium-text mh-2 mv-0">{strings.adherentSearchActivities}</p>
              {ACTIVITIES.map(({ code, id }) => (
                <CheckboxComponent
                  handleChange={() => {
                    setActivities((state) => {
                      if (state.filter((item) => item === id)?.length === 0) {
                        return state.concat([id]);
                      }
                      return state.filter((item) => item !== id);
                    });
                  }}
                  label={code}
                  id={id}
                  value={activities?.filter((item) => item === id)?.length > 0}
                />
              ))}
            </div>
          </div>

        </div>

        <AdherentMap
          agencyAdherentAdmins={getAll.data?.data?.agencyAdherentAdmins}
          dimensions={{ width: getContainerWidth(), height: 750 }}
        />

      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default AdherentBookMap;
