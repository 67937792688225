import strings from './strings';

export const ROLES = [
  { label: strings.adherentRoles.president, value: 'President' },
  { label: strings.adherentRoles.vicePresident, value: 'VicePresident' },
  { label: strings.adherentRoles.treasurer, value: 'Treasurer' },
  { label: strings.adherentRoles.secretary, value: 'Secretary' },
  { label: strings.adherentRoles.member, value: 'Member' },
  { label: strings.adherentRoles.other, value: 'Other' },
];

export const JOBS = [
  { label: strings.adherentJobs.justSAV, value: 'CustomerServiceOnly' },
  { label: strings.adherentJobs.installation, value: 'CustomerServiceAndInstall' },
];

export const ACTIVITIES = [
  { code: strings.adherentAllActivites.gas, id: 'GAZ' },
  { code: strings.adherentAllActivites.heating, id: 'PAC' },
  { code: strings.adherentAllActivites.freshing, id: 'CLIM' },
  { code: strings.adherentAllActivites.bio, id: 'Biomasse' },
  { code: strings.adherentAllActivites.pv, id: 'PV' },
  { code: strings.adherentAllActivites.sanitary, id: 'Sanitaire' },
];

export const EMPLOYEE_NUMBERS = [
  { label: '1-5', value: 1 },
  { label: '6-10', value: 6 },
  { label: '11-25', value: 11 },
  { label: '26-50', value: 26 },
  { label: '50+', value: 50 },
];
